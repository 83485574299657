.product{
    flex: 4;
    padding: 20px;
}
.productTitleContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.productAddBtn{
    width: 80px;
    border: none;
    padding: 5px;
    background-color: teal;
    color: white;
    font-size: 14px;
    cursor: pointer;
}
.productTop{
    display: flex;
}
.productTopLeft,.productTopRight{
    flex: 1;
}
.productTopLRight{
    padding: 20px;
    margin: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.9); 
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
}
.infoImg{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}
.productInfoTop{
    display: flex;
    align-items: center;
}
.productNme{
    font-weight: 600;
}
.productBottomInfo{
    margin-top: 10px;
}
.productInfoItem{
    width: 150px;
    display: flex;
    justify-content: space-between;
}
.productInfoValue{
    font-weight: 300;
}
.productBottom{
    padding: 20px;
    margin: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.9); 
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
}
.productForm{
    display: flex;
    justify-content: space-between;
}
.productFormLeft{
    display: flex;
    flex-direction: column;
}
.productFormLeft>label{
    margin-bottom: 10px;
    color: gray;
}
.productFormLeft>input{
    margin-bottom: 10px;
    border: none;
    padding: 5px;
    border-bottom: 1px solid gray;
}
.productImg{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}
.productFormLeft>select{
    margin-bottom: 10px;
}
.productFormRight{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.productUpload{
    display: flex;
    align-items: center;
}
.productBtn{
    border: none;
    padding: 5px;
    border-radius: 5px;
    background-color: darkblue;
    color: white;
    font-weight: 600;

cursor: pointer;}