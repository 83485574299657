.feedback-form {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.feedback-form h2 {
    text-align: center;
    margin-bottom: 20px;
}

.feedback-form .form-group {
    margin-bottom: 15px;
}

.feedback-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.feedback-form input[type="email"],
.feedback-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.feedback-form button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.feedback-form button:hover {
    background-color: #0056b3;
}

.feedback-form p {
    text-align: center;
    margin-top: 20px;
    color: green;
}
