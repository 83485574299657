.widegtLg{
    flex: 2;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.9); 
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
}
.widegtTitle{
    font-size: 22px;
    font-weight: 600;
    color: black;
}
.widegtTable{
    width: 100%;
    border-spacing: 20px;
}
.widegtTh{
    text-align: left;
}
.widegtName{
    margin-left: 10px;
}
.widegtDate , .widegtAmount{
    font-weight: 300;
}
.widegtTdUser{
display: flex;
align-items: center;
font-weight: 600;
}
.widegtLgBtn{
    border: none;
    padding: 7px 10px;
    border-radius: 10px;
    cursor: pointer;
}
.widegtLgBtn.Approved{
    background-color: #e5faf2;
    color: #3bb077;
}
.widegtLgBtn.Pennding{
    background-color: #ebf1fe;
    color: #3bb077;
}
.widegtLgBtn.Declined{
    background-color: #fff0f1;
    color: #d95087;
}