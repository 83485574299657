.sidebar{
    flex: 1;
    height: calc(100vh - 50px);
    background-color:rgb(251, 251, 255);
    position: sticky;
    top: 50px;
    flex-wrap: wrap;
}
.sidebarwrapper{
    padding: 20px;
    color: #555;
}
.sidebarmenu{
    margin-bottom: 10px;
}
h3{
    font-size: 13px;
    color: rgb(187, 186, 186);
}
li{
    list-style: none;
    padding: 5px;
}
ul{
    padding: 5px;
    cursor: pointer;
    display: flex;

flex-direction: column;
border-radius: 10px;
}
li.active ,li:hover{
background-color: rgb(228, 228, 250);
}
.sidebaricon{
    margin-right: 5px;
    font-size: 20px !important;
}
.links{
text-decoration: none;
 color: inherit 

}