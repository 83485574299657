.topbar{
    width: 100%;
    height: 56px;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 397;

}
.topbarwrapper{
    height: 100%;
    pad: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo{
     font-weight: bold;
     font-size: 30px;
     color: darkblue;
     margin-left: 15px;
     cursor: pointer;
}
.topbariconcontainer{
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    color: #555;
}
.topiconbar{
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: 0;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items:center;
    justify-content: center;
    font-size: 10px;
}
.topright{
    display: flex;

}
.topavatar{
    margin: -2px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
}