.chart{
    margin: 20px;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.9); 
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
}
.chart h3{
    margin: 15px;
    font-size: 20px;
    color: black;
}