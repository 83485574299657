.newUser{
    flex: 4;
}
.newUserForm{
    display: flex;
    flex-wrap: wrap;
}
.newUseerItem{
    width: 400px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 20px;
}
.newUseerItem>label{
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 600;
    color: gray
}
.newUseerItem>input{
    height: 20px;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 5px;
}
.newUserGenger>input{
    margin-top: 10px;

}
.newUserGenger>label{
    margin: 10px;
    font-size: 17px;
    color: #555;
}
.newUserSelect{
    height: 40px;
    border-radius: 5px;
}
.newUserBtn{
    width: 200px;
    height: 30px;
    border: none;
    background-color: darkblue;
    color: white;
    padding: 7px 10px;
    font-weight: 600;
border-radius: 10px;
margin-left: 15px;
margin-top: 50px;
cursor: pointer;
}