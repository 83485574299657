.widegtSm{
    flex: 1;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.9); 
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    margin-right: 20px;
}
.widegtSmTilte{
    font-size: 22px;
    font-weight: 600;
}
 img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.widgetSmList{
    margin: 0;
    padding: 0;
}
.widegtSmListItem{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}
.widegtSmUser{
    display: flex;
    flex-direction: column;
}
.btnSm{
    display: flex;
    align-items: center;
    border: none;
    border-radius: 10px;
    padding: 7px 10px;
    background-color: #eeeef7;
    color: #555;
    cursor: pointer;
}
.widegtIcon{
    font-size: 14px;
    margin-right: 5px;
}