.update{
    flex: 4;
    padding: 20px;
}
.userTitleContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.userBtn{
    width: 80px;
    border: none;
    padding: 5px;
    background-color: teal;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
}
.userContainer{
    display: flex;
    margin-top: 20px;
}
.userShow{
    flex: 1;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.9); 
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
}
.userUpdate{
    flex: 2;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.9); 
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    margin-left: 20px;
}
.userShowTop{
    display: flex;
    align-items: center;
}
.userShowTopTitle{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}
.userShowUserName{
    font-weight: 600;
}
.userShowUserTitle{
    font-weight: 300;
}
.userShowBooton{
    margin-top: 20px;
}
.userShowTitle{
    font-size: 14px;
    font-weight: 600;
    color: rgb(175, 170, 170);
}
.userShowInfo{
    display: flex;
    align-items: center;
    margin: 20px 0;
    color: #444;
}
.userShowIcon{
    font-size: 16px !important;
}
.userShowInfoTitle{
    margin-left: 10px;
}
.userUpdateImg{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 20px;
}
.userUpdateform{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}
.userUpdateTitle{
    font-size: 24px;
    font-weight: 600;
}
.userUpdateItem{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.userUpdateItem>label{
    margin-bottom: 10px;
    font-size: 14px;
}
.userUpdateInput{
    border: none;
    width: 250px;
    border-bottom: 1px solid gray;
    height: 30px;
}
.userUpdateRight{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}
.userUpdateUpload{
    display: flex;
align-items: center;
}
.userUpdateBtn{
    border-radius: 5px;
    border: none;
    padding: 5px;
    cursor: pointer;
    background-color: darkblue;
    color: white;
}