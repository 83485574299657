.featured{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.featureditem{
    flex: 1;
    margin: 0 20px;
    padding: 30px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.9); 
box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
}
.featuretitle{
    font-size: 20px;
}
.featuredmony{
    margin: 10 0;
    display: flex;
    align-items: center;
}
.money{
font-weight: bold;
font-size: 30px;
}
.moneyrate{
    display: flex;
    align-items: center;
margin-left: 20px;
}
.featureicon{
font-size: 14px;
margin-left: 5px;
color: green;
}
.featureicon.negative{
    color: red;
}
.featuresub{
    font-size: 15px;
    color: gray;
}