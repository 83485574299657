.userList{
    flex: 4;
}
.action{
    display: flex;
    align-items: center;
padding: 5px 10px;
cursor: pointer;
}
.edit{
    color: green;
}
.delete{
    color: red;
    margin-bottom: 17px;
    padding-left: 10px;
}
.Edit{
    text-align: center;
    margin-bottom: 5px;
}