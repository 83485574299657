@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

*{
  font-family: "Source Sans 3", sans-serif; 
  margin: 0;
  background-color: whitesmoke;
}

.container{
  display: flex;
  height: calc(100vh - 50px);
  margin-top: 10px;
}
.outlet{
  flex: 4;
}
